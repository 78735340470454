import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callGetCompanyUsers, callUpdateAccount } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, AlertPopUp } from '@atoms';
import { callDeleteUser, callDeleteInviteUser } from '@api';

interface TeamTableProps {
  companyId: string;
  refresh?: number;
  isSuperAdmin?: boolean;
  selectedTeam?: Team;
  onEdit: (agent: Agent) => void;
}

const TeamTable: React.FC<TeamTableProps> = (props: TeamTableProps) => {
  const { companyId, refresh, onEdit, isSuperAdmin, selectedTeam } = props;

  const {
    state: {
      teamPagination: { page, perPage }
    }
  } = useContext(TableContext);

  const [deleteUser, setDeleteUser] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [teams, setTeams] = useState<Pagination<Agent[]>>();

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetCompanyUsers({
      companyId,
      teamId: selectedTeam?.id,
      pageNumber: page,
      pageSize: perPage
    });
    if (code == 200) {
      setTeams(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, refresh, selectedTeam]);

  const onSwitch = async (agent: Agent, value: unknown, type: string) => {
    const payload: any = {};
    if (type === 'sync') {
      payload.autoChatSync = value;
      setLoading(true);
      const { code, error } = await callUpdateAccount(agent.id, payload);

      if (code >= 400 && error) {
        setError(error.message);
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        api();
      }
    } else if (type === 'status') {
      payload.active = value;
      setLoading(true);
      const { code, error } = await callUpdateAccount(agent.id, payload);

      if (code >= 400 && error) {
        setError(error.message);
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        api();
      }
    } else if (type == 'recordType') {
      payload.preferredRecordType = value;
      setLoading(true);
      const { code, error } = await callUpdateAccount(agent.id, payload);

      if (code >= 400 && error) {
        setError(error.message);
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        api();
      }
    } else if (type == 'edit') {
      onEdit(agent);
    } else if (type == 'delete') {
      setDeleteUser(agent.id);
    }
  };

  const apiDeleteUser = async (agentId: string | null) => {
    if (agentId == null) {
      return;
    }
    setLoading(true);
    const { code, error } = await callDeleteUser(agentId);
    if (code >= 400 && error) {
      setError(error.message);
      setTimeout(() => {
        setError(undefined);
        setLoading(false);
      }, 3000);
    } else {
      // delete invited user api calling
      const { code, error } = await callDeleteInviteUser(agentId);
      if (code >= 400 && error) {
        setError(error.message);
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        api();
      }
    }
  };

  const rowData: TableRow[] = teams
    ? generateRows(teams.data, onSwitch, isSuperAdmin ?? false)
    : [];

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}
      <Table name="team" columns={columns} data={rowData} isLoading={loading} pagination={teams} />
      <AlertPopUp
        open={deleteUser != null}
        title={'Delete'}
        message={'Are you sure, you want to delete account?'}
        onSuccess={() => {
          apiDeleteUser(deleteUser);
          setDeleteUser(null);
        }}
        onClose={() => {
          setDeleteUser(null);
        }}
      />
    </Box>
  );
};

export default TeamTable;
