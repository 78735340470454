import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import TextView from '../../../../../atoms/textView';
import { DateTime } from 'luxon';
import { callGetAccountPerformance } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';

interface AccountPerformanceTableProps {
  start: Date;
  end: Date;
}

const AccountPerformanceTable: React.FC<AccountPerformanceTableProps> = (
  props: AccountPerformanceTableProps
) => {
  const { start, end } = props;

  const {
    state: {
      accountPerformancePagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);

  const [accountPerformance, setAccountPerformance] = useState<Pagination<AccountPerformance[]>>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const api = async () => {
      const startDate = DateTime.fromJSDate(start).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      const endDate = DateTime.fromJSDate(end).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      if (startDate && endDate) {
        setLoading(true);
        const { code, data } = await callGetAccountPerformance({
          from: startDate,
          to: endDate,
          pageNumber: page,
          pageSize: perPage,
          sortBy: orderBy
        });

        if (code === 200 && data) {
          setAccountPerformance(data);
        }
        setLoading(false);
      }
    };

    api();
  }, [page, perPage, start, end, orderBy]);

  const rowData: TableRow[] = accountPerformance?.data ? generateRows(accountPerformance.data) : [];

  return (
    <Box>
      <TextView $fontSize={30} mb={3}>
        Account Performance
      </TextView>
      <Table
        name="account_performance"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={accountPerformance}
        // error={error}
        // selectable
      />
    </Box>
  );
};

export default AccountPerformanceTable;
