const columns: TableColumn[] = [
  {
    Header: `Name`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Email`,
    accessor: `email`,
    dbKey: `email`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Phone`,
    accessor: `phone`,
    dbKey: `phone`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Team`,
    accessor: `team`,
    dbKey: `team`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Status`,
    accessor: `active`,
    dbKey: `active`,
    isString: false,
    isNumeric: false,
    isDate: false,
    isBool: true,
    isSortable: true
  },
  {
    Header: `Auto chat sync`,
    accessor: `autoChatSync`,
    dbKey: `autoChatSync`,
    isString: false,
    isNumeric: false,
    isDate: false,
    isBool: true,
    isSortable: true
  },
  {
    Header: `Preferred Record type`,
    accessor: `preferredRecordType`,
    dbKey: `preferredRecordType`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Action`,
    accessor: `edit`,
    dbKey: `edit`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
