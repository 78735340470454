import * as React from 'react';
import { TextView } from '@atoms';
import { Stack } from '@mui/material';
import { ReactChild } from 'react';

interface CardProps {
  title: string;
  count: string;
  icon: ReactChild;
}

const CommonCard = (props: CardProps) => {
  const { title, count, icon } = props;

  return (
    <Stack
      direction={'row'}
      sx={{
        backgroundColor: '#CFDBD5',
        borderRadius: 1,
        p: 2,
        minHeight: 100,
      }}>
      <Stack sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <TextView $fontWeight={400} $fontSize={16} textAlign="left">
          {title}
        </TextView>
        <div style={{ flex: 1 }} />
        <TextView $fontSize={32}>
          {count}
        </TextView>
      </Stack>
      {icon}
    </Stack>
  );
};

export default CommonCard;
