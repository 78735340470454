import React, { ReactChild } from 'react';
import { Stack, Box, Grid } from '@mui/material';

import { TextView } from '@atoms';
import { CardPayment } from '@organisms';

import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconLicensed } from '@assets/svg/licensed.svg';
import { ReactComponent as IconPaidDate } from '@assets/svg/paid-date.svg';
import { ReactComponent as IconLicensedCircles } from '@assets/svg/licensed-circles.svg';
import { ReactComponent as IconCardCircles } from '@assets/svg/card-circles.svg';
import { DateTime } from 'luxon';

interface CardProps {
  title: string;
  subtitle?: string;
  bgColor?: string;
  fontColor?: string;
  count: string;
  icon: ReactChild;
}

const Card = (props: CardProps) => {
  const { title, count, icon, bgColor = 'rgba(36, 36, 35, 0.08)', fontColor = '#242423' } = props;
  return (
    <Stack direction={'row'} sx={{ backgroundColor: bgColor, borderRadius: 1, p: 2 }}>
      <Box flex="1">
        <TextView $fontWeight={400} $fontColor={fontColor}>
          {title}
        </TextView>
        <TextView $fontSize={32} $fontColor={fontColor}>
          {count}
        </TextView>
      </Box>
      {icon}
    </Stack>
  );
};

interface CardListProps {
  licensed: number;
  active: number;
  license?: License;
}

const CardList: React.FC<CardListProps> = ({ licensed, active, license }: CardListProps) => {
  const startDate = license?.startDate;
  const endDate = license?.endDate;

  return (
    <Grid container columnSpacing={4}>
      <Grid item sm={12} md={4}>
        <Card
          title="Current active users"
          count={`${active}`}
          icon={<IconTeam />}
          bgColor="#CFDBD5"
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <Card
          title="Current Active Licences"
          count={`${licensed}`}
          icon={<IconLicensed />}
          bgColor="#086BDE"
          fontColor="#ffffff"
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <Card
          title="Paid till date"
          count={endDate ? DateTime.fromISO(endDate).toFormat('dd LLL yyyy') : ''}
          icon={<IconPaidDate />}
        />
      </Grid>
      <Grid item sm={12} md={6} mt={4}>
        <CardPayment
          title="Current subscription plan"
          subtitle={`From ${
            startDate ? DateTime.fromISO(startDate).toFormat('dd LLL yyyy') : ''
          } to ${endDate ? DateTime.fromISO(endDate).toFormat('dd LLLL yyyy') : ''}`}
          count={`£${license?.amount ?? 0}`}
          icon={<IconLicensedCircles />}
          bgColor="#F5CB5C"
        />
      </Grid>
      <Grid item sm={12} md={6} mt={4}>
        <CardPayment
          title="Next payment"
          subtitle={`on ${endDate ? DateTime.fromISO(endDate).toFormat('dd LLLL yyyy') : ''}`}
          count={`£${license?.amount ?? 0}`}
          icon={<IconCardCircles />}
        />
      </Grid>
    </Grid>
  );
};

export default CardList;
