const generateRows = (list: AccountPerformance[]): TableRow[] =>
  list.map((item) => {
    const activeUser = item.active ?? 0;
    const inactiveUser = item.inactive ?? 0;
    const totalUser = activeUser + inactiveUser;

    const sent = item.sent ?? 0;
    const received = item.received ?? 0;
    const totalMessage = sent + received;

    return {
      name: item.name ?? '',
      users: totalUser,
      active: activeUser,
      syned: totalMessage,
      sent: sent,
      received: received,
      average: isNaN(totalMessage / totalUser) ? '-' : (totalMessage / totalUser).toFixed(0)
      // linkData: {
      //   to: `${routes.keywordsDetails}?id=${keyword.id}`
      // }
    };
  });

export default generateRows;
