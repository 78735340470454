import React, { ReactChild } from 'react';
import { TextView } from '../../../../../atoms';
import { Grid, Stack } from '@mui/material';
import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconActive } from '@assets/svg/active.svg';
import { ReactComponent as IconInActive } from '@assets/svg/inactive.svg';

interface CardProps {
  title: string;
  count: string;
  icon: ReactChild;
  color?: string;
  fontColor?: string;
}

const Card = (props: CardProps) => {
  const { title, count, icon, color = '#CFDBD5', fontColor = '#242423' } = props;
  return (
    <Stack direction={'row'} sx={{ backgroundColor: color, borderRadius: 1, pt: 2, pb: 2, pl: 2 }}>
      <Stack flex="1">
        <TextView $fontWeight={400}  $fontColor={fontColor}>
          {title}
        </TextView>
        <TextView $fontSize={32} $fontColor={fontColor} sx={{ mt: 1 }}>
          {count}
        </TextView>
      </Stack>
      <Stack>{icon}</Stack>
    </Stack>
  );
};

interface CardListProps {
  totalUser: number;
  activeUser: number;
  inactiveUser: number;
}

const CardList: React.FC<CardListProps> = ({
  totalUser,
  activeUser,
  inactiveUser
}: CardListProps) => {
  return (
    <Grid columnSpacing={4} container mt={2}>
      <Grid md={4} item>
        <Card title="Total Users" count={`${totalUser}`} icon={<IconTeam />} />
      </Grid>
      <Grid md={4} item>
        <Card
          title="Active Users"
          count={`${activeUser}`}
          icon={<IconActive color="rgba(255, 255, 255, 0.16)" />}
          color={'#086BDE'}
          fontColor={'#FFFFFF'}
        />
      </Grid>
      <Grid md={4} item>
        <Card
          title="Inactive Users"
          count={`${inactiveUser}`}
          icon={<IconInActive />}
          color={'rgba(36, 36, 35, 0.08)'}
        />
      </Grid>
    </Grid>
  );
};

export default CardList;
