import React, { ReactChild } from 'react';
import { TextView } from '../../../../../atoms';
import { Stack, Box, Grid } from '@mui/material';
import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconLicensed } from '@assets/svg/licensed.svg';
import { ReactComponent as IconActive } from '@assets/svg/active.svg';
interface CardProps {
  title: string;
  subtitle?: string;
  bgColor?: string;
  fontColor?: string;
  count: number;
  icon: ReactChild;
}

const Card = (props: CardProps) => {
  const { title, count, icon, bgColor = 'rgba(36, 36, 35, 0.08)', fontColor = '#242423' } = props;
  return (
    <Stack direction={'row'} sx={{ backgroundColor: bgColor, borderRadius: 1, p: 2 }}>
      <Box flex="1">
        <TextView $fontWeight={400}  $fontColor={fontColor}>
          {title}
        </TextView>
        <TextView $fontSize={32} $fontColor={fontColor}>
          {count}
        </TextView>
      </Box>
      {icon}
    </Stack>
  );
};

interface CardListProps {
  accounts: number;
  licensed: number;
  active: number;
}

const CardList: React.FC<CardListProps> = ({ accounts, licensed, active }: CardListProps) => {
  return (
    <Grid container columnSpacing={4} mt={3}>
      <Grid item sm={12} md={4}>
        <Card title="Total Accounts" count={accounts} icon={<IconTeam />} bgColor="#CFDBD5" />
      </Grid>
      <Grid item sm={12} md={4}>
        <Card
          title="Total Active Licences"
          count={licensed}
          icon={<IconLicensed />}
          bgColor="#086BDE"
          fontColor="#ffffff"
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <Card title="Total Active Users" count={active} icon={<IconActive />} />
      </Grid>
    </Grid>
  );
};

export default CardList;
