import React, { useEffect, useState, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, LinearProgress } from '@mui/material';
import { AddUserModal, CommonCardList, Header } from './particles';
import { callGetTeamsList, callGetTotalUsers } from '@api';
import { ApplicationContext } from '@particles';
import { Alert, TextView, Button } from '@atoms';
import { AddCompanyTeam, CompanyTeam, TeamTable } from '@organisms';

const Team: React.FC<RouteComponentProps> = () => {
  const {
    state: { user }
  } = useContext(ApplicationContext);

  const [activeInactive, setActiveInactive] = useState<ActiveInactive>({ active: 0, inactive: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<Agent>();
  const [alert, setAlert] = useState<
    { title: string; message: string; type: 'success' | 'error' } | undefined
  >(undefined);

  const [teamList, setTeamList] = useState<Team[]>([]);
  const [selectedTeamForFilter, setSelectedTeamForFilter] = useState<Team>();
  const [openAddTeam, setOpenAddTeam] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<Team>();

  const api = async () => {
    setLoading(true);

    const { data: dataUser, code: codeUsers } = await callGetTotalUsers({
      companyId: user?.companyId
    });
    if (codeUsers == 200 && dataUser) {
      const total = dataUser.data.total;
      setActiveInactive(total);
      setRefresh(Date.now());
    }

    setLoading(false);
  };

  useEffect(() => {
    api();
  }, []);

  if (!user) {
    return <div>User not found</div>;
  }

  const teamApi = () => {
    if (user.companyId) {
      callGetTeamsList({ companyId: user.companyId }).then((res) => {
        setTeamList(res.data ?? []);
      });
    }
  };

  return (
    <Box sx={{ p: 3, height: 'inherit' }}>
      {loading && <LinearProgress />}
      <Header
        onAddSuccess={() => {
          api();
          setAlert({ title: 'Success', message: 'User invited successfully.', type: 'success' });
          setTimeout(() => {
            setAlert(undefined);
          }, 3000);
        }}
        onUploadSuccess={() => {
          setAlert({ title: 'Success', message: 'Users invited successfully.', type: 'success' });
          setTimeout(() => {
            setAlert(undefined);
          }, 3000);
          api();
        }}
        onTeamChange={(team?: Team) => {
          setSelectedTeamForFilter(team);
        }}
        teamList={teamList}
      />

      {alert && <Alert severity={alert.type} title={alert.title} message={alert.message} />}
      <CommonCardList
        totalUser={activeInactive.active + activeInactive.inactive}
        activeUser={activeInactive.active}
        inactiveUser={activeInactive.inactive}
      />
      <TeamTable
        refresh={refresh}
        selectedTeam={selectedTeamForFilter}
        companyId={user.companyId}
        onEdit={(agent: Agent) => {
          setSelectedAgent(agent);
          setOpenAddUser(true);
        }}
      />

      <AddUserModal
        open={openAddUser}
        selectedAgent={selectedAgent}
        onClose={() => {
          setOpenAddUser(false);
          setSelectedAgent(undefined);
        }}
        onSuccess={() => {
          setOpenAddUser(false);
          setSelectedAgent(undefined);
          setRefresh(Date.now());
        }}
      />

      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mt: 2 }}>
        <TextView display={'initial'} $fontSize={30}>
          All Teams
        </TextView>
        <Box sx={{ flex: 1 }} />
        <Button onClick={() => setOpenAddTeam(true)}>{'Add Team'}</Button>
      </Box>

      <CompanyTeam
        companyId={user.companyId}
        refresh={refresh}
        isSuperAdmin={true}
        onEdit={(team: Team) => {
          setSelectedTeam(team);
          setOpenAddTeam(true);
        }}
      />

      <AddCompanyTeam
        open={openAddTeam}
        selectedTeam={selectedTeam}
        companyId={user.companyId}
        onClose={() => {
          setOpenAddTeam(false);
          setSelectedTeam(undefined);
        }}
        onSuccess={() => {
          setOpenAddTeam(false);
          setSelectedTeam(undefined);
          teamApi();
          setRefresh(Date.now());
        }}
      />
    </Box>
  );
};

export default Team;
