import React, { useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';

import { CommonCard, AccountTable } from './particles';

import { Box } from '@mui/material';
import { TableContext } from '@particles';
import { TextView } from '@atoms';
import { callGetLicenseLatest, callGetTotalUsers } from '@api';

const AllAccount: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      allAccountPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);

  const [total, setTotal] = useState({
    accounts: 0,
    licensed: 0
  });

  const [activeUser, setActiveUser] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<Pagination<LicenseCurrent>>();

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      const { code, data } = await callGetLicenseLatest({
        pageNumber: page,
        pageSize: perPage,
        sortBy: orderBy
      });
      if (code == 200 && data) {
        setTotal(data.data.total);
        setLicenses(data);
      }

      const { code: codeUser, data: dataUser } = await callGetTotalUsers({});
      if (codeUser == 200) {
        setActiveUser(dataUser?.data?.total?.active ?? 0);
      }

      setLoading(false);
    };
    api();
  }, [page, perPage, orderBy]);

  return (
    <Box sx={{ m: 4 }}>
      <TextView display={'initial'} $fontSize={30}>
        All Accounts
      </TextView>

      <CommonCard accounts={total.accounts} licensed={total.licensed} active={activeUser} />

      <AccountTable licenses={licenses} isLoading={loading} />
    </Box>
  );
};

export default AllAccount;
