export const callUploadInviteUsers = async (file: File): Promise<iResponse<any>> => {
  const data = new FormData();
  data.append('file', file);

  const token = localStorage.getItem('token');

  const apiBase = process.env.REACT_APP_API_BASE;
  const url = `${apiBase}/agent/inviteMany`;

  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${token}` ?? ''
    },
    method: 'POST',
    body: data
  };

  const request: Response = await fetch(url, fetchOptions);
  const responseData = await request.json();

  if (request.status >= 400) {
    const response: iResponse<any> = {
      code: request.status,
      error: responseData as ResponseErrorObj
    };
    return response;
  } else {
    const response: iResponse<any> = {
      data: responseData,
      code: request.status
    };
    return response;
  }
};

export default callUploadInviteUsers;
