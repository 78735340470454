import SvgIcon from '@mui/material/SvgIcon';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import MarginRoundedIcon from '@mui/icons-material/MarginRounded';
import AnalyticsIcon from '@mui/icons-material/BarChartRounded';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MoneyIcon from '@mui/icons-material/CurrencyPoundOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { routes } from '../../../particles/common';
import AssistantIcon from '@mui/icons-material/Code';
export type MenuItem = {
  label: string;
  Icon: typeof SvgIcon;
  cssClass?: string;
  to?: string;
};

const menuConfigAdmin: MenuItem[] = [
  {
    label: `Dashboard`,
    Icon: DashboardIcon,
    cssClass: `primary`,
    to: routes.adminDashboard
  },
  {
    label: `Team`,
    Icon: GroupsIcon,
    cssClass: `primary`,
    to: routes.adminTeam
  },
  {
    label: `Account`,
    Icon: AccountCircleIcon,
    cssClass: `primary`,
    to: routes.adminAccount
  },
  {
    label: `Billing`,
    Icon: MoneyIcon,
    cssClass: `primary`,
    to: routes.adminBilling
  }
];

const menuConfigSuperAdmin: MenuItem[] = [
  {
    label: `Dashboard`,
    Icon: DashboardIcon,
    cssClass: `primary`,
    to: routes.SA_Dashboard
  },
  {
    label: `Negotiator`,
    Icon: GroupsIcon,
    cssClass: `primary`,
    to: routes.SA_Negotiator
  },
  {
    label: `Billing Accounts`,
    Icon: LocationCityIcon,
    cssClass: `primary`,
    to: routes.SA_BillingAccounts
  },
  {
    label: `Markup Margins`,
    Icon: MarginRoundedIcon,
    cssClass: `primary`,
    to: routes.SA_MarkupMargins
  },
  {
    label: `Analytics`,
    Icon: AnalyticsIcon,
    cssClass: `primary`,
    to: routes.SA_Analytics
  },
  {
    label: `Usage History`,
    Icon: TokenOutlinedIcon,
    cssClass: `primary`,
    to: routes.SA_UsageHistory
  },
  {
    label: `Custom Values`,
    Icon: AssistantIcon,
    cssClass: `primary`,
    to: routes.SA_CustomValues
  },
  {
    label: `Settings`,
    Icon: SettingsIcon,
    cssClass: `primary`,
    to: routes.SA_Settings
  }
];

export default { menuConfigAdmin, menuConfigSuperAdmin };
